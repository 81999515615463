import axios from "axios";
import App from "./App.vue";
import Buefy from "buefy";
import ImageKit from "imagekitio-vue";
import infiniteScroll from "vue-infinite-scroll";
import Vue from "vue";
import VueAxios from "vue-axios";
import VueRouter from "vue-router";
import VueZoomer from "vue-zoomer";
import xlsx from "xlsx";

Vue.use(Buefy, {
  customIconPacks: {
    "mdi": {
      internalIcons: {
        "eye": "eye-off",
        "eye-off": "eye"
      }
    },
    "fas": {
      internalIcons: {
        "eye": "eye-slash",
        "eye-off": "eye"
      }
    }
  }
});

Vue.use(ImageKit, {
  urlEndpoint: "https://ik.imagekit.io/ksttoko",
  publicKey: "public_e3eD7OPixRi238qredXPaI1R4dg="
});
Vue.use(VueAxios, axios);
Vue.use(VueRouter);
Vue.use(VueZoomer);
Object.defineProperty(Vue.prototype, "$xlsx", { value: xlsx });
Vue.config.productionTip = false;
Vue.use(infiniteScroll)

/** utils **/

import ValidationUtils from "./utils/ValidationUtils";
import Cleave from "vue-cleave-component";
import Treeselect from "@riophae/vue-treeselect";
import VueBarcode from "vue-barcode";


Vue.use(ValidationUtils);
Vue.use(Cleave);
Vue.component("treeselect", Treeselect);
Vue.component("barcode", VueBarcode);

/** plugin */

import Chart from "chart.js";
import ChartAnnotationsPlugin from "chartjs-plugin-annotation";
import JQuery from "jquery";
import JQueryEasing from "jquery.easing";
import KstApp from "./services/App/App.js";

Chart.plugins.register(ChartAnnotationsPlugin);

const plugin = {
  install () {
    Vue.prototype.$chart = Chart;
    Vue.prototype.$kst = KstApp;
    Vue.prototype.$jquery = JQuery;
  }
};
Vue.use(plugin);
Vue.use(JQueryEasing);

/** additional lib **/

import "moment/locale/id";

import "./style/css/style.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@fortawesome/fontawesome-free/js/all.min.js";
import "@mdi/font/css/materialdesignicons.css";

/** shared components **/

import Action from "./components/_shared/Action/Action.vue";
import Actions from "./components/_shared/Action/Actions.vue";
import ActionItem from "./components/_shared/Action/ActionItem.vue";
import Barcode from "./components/_shared/Barcode/Barcode.vue";
import Button from "./components/_shared/Button/Button.vue";
import Check from "./components/_shared/Check/Check.vue";
import Container from "./components/_shared/Container/Container.vue";
import Created from "./components/_shared/Created/Created.vue";
import DashboardList from "./components/_shared/DashboardList/DashboardList.vue";
import DashboardList2 from "./components/_shared/DashboardList2/DashboardList2.vue";
import DashboardSummary from "./components/_shared/DashboardSummary/DashboardSummary.vue";
import DialogConfirm from "./components/_shared/DialogConfirm/DialogConfirm.vue";
import Divider from "./components/_shared/Divider/Divider.vue";
import Dropdown from "./components/_shared/Dropdown/Dropdown.vue";
import Error from "./components/_shared/Error/Error.vue";
import Errors from "./components/_shared/Error/Errors.vue";
import ErrorsHelper from "./components/_shared/Error/ErrorsHelper.vue";
import Failure from "./components/_shared/Failure/Failure.vue";
import Field from "./components/_shared/Field/Field.vue";
import Form from "./components/_shared/Form/Form.vue";
import HeaderBlank from "./components/_shared/Table/HeaderBlank.vue";
import Icon from "./components/_shared/Icon/Icon.vue";
import Image from "./components/_shared/Image/Image.vue";
import Input from "./components/_shared/Input/Input.vue";
import ItemActive from "./components/_shared/Status/ItemActive.vue";
import ItemStatus from "./components/_shared/Status/ItemStatus.vue";
import LastUpdated from "./components/_shared/LastUpdated/LastUpdated.vue";
import List from "./components/_shared/List/List.vue";
import LoadData from "./components/_shared/LoadData/LoadData.vue";
import Loading from "./components/_shared/Loading/Loading.vue";
import Menu from "./components/_shared/Menu/Menu.vue";
import Modal from "./components/_shared/Modal/Modal.vue";
import ModalConfirm from "./components/_shared/ModalConfirm/ModalConfirm.vue";
import ModalInput from "./components/_shared/ModalInput/ModalInput.vue";
import ModalSearch from "./components/_shared/ModalSearch/ModalSearch.vue";
import ModalSelect from "./components/_shared/ModalSelect/ModalSelect.vue";
import ModalView from "./components/_shared/ModalView/ModalView.vue";
import NumberWithDate from "./components/_shared/NumberWithDate/NumberWithDate.vue"
import Output from "./components/_shared/Output/Output.vue";
import Page from "./components/_shared/Page/Page.vue";
import PageBlank from "./components/_shared/PageBlank/PageBlank.vue";
import PageDetails from "./components/_shared/PageDetails/PageDetails.vue";
import PageDelete from "./components/_shared/PageDelete/PageDelete.vue";
import PageEdit from "./components/_shared/PageEdit/PageEdit.vue";
import PageExport from "./components/_shared/PageExport/PageExport.vue";
import PageExportDetails from "./components/_shared/PageExportDetails/PageExportDetails.vue";
import PageList from "./components/_shared/PageList/PageList.vue";
import PageNew from "./components/_shared/PageNew/PageNew.vue";
import PagePrint from "./components/_shared/PagePrint/PagePrint.vue";
import PagePrintDetails from "./components/_shared/PagePrintDetails/PagePrintDetails.vue";
import PagePrintList from "./components/_shared/PagePrintList/PagePrintList.vue";
import PageReport from "./components/_shared/PageReport/PageReport.vue";
import PageSearch from "./components/_shared/PageSearch/PageSearch.vue";
import Print from "./components/_shared/Print/Print.vue";
import PrintSign from "./components/_shared/Print/PrintSign.vue";
import PrintTable from "./components/_shared/Print/PrintTable.vue";
import RouterBack from "./components/_shared/Router/RouterBack.vue";
import RouterForward from "./components/_shared/Router/RouterForward.vue";
import Section from "./components/_shared/Section/Section.vue";
import SectionSummary from "./components/_shared/Section/SectionSummary.vue";
import Skeleton from "./components/_shared/Skeleton/Skeleton.vue";
import Status from "./components/_shared/Status/Status.vue";
import SubTitle from "./components/_shared/Title/SubTitle.vue";
import Table from "./components/_shared/Table/Table.vue";
import TableEmpty from "./components/_shared/Table/TableEmpty.vue";
import Title from "./components/_shared/Title/Title.vue";
import Tooltip from "./components/_shared/Tooltip/Tooltip.vue";
import Upload from "./components/_shared/Upload/Upload.vue";
import Value from "./components/_shared/Value/Value.vue";
import Wait from "./components/_shared/Wait/Wait.vue";
import Warning from "./components/_shared/Warning/Warning.vue";
import VueRecaptcha from "vue-recaptcha";

Vue.component("kst-action", Action);
Vue.component("kst-actions", Actions);
Vue.component("kst-action-item", ActionItem);
Vue.component("kst-barcode", Barcode);
Vue.component("kst-button", Button);
Vue.component("kst-check", Check);
Vue.component("kst-container", Container);
Vue.component("kst-created", Created);
Vue.component("kst-dashboard-list", DashboardList);
Vue.component("kst-dashboard-list2", DashboardList2);
Vue.component("kst-dashboard-summary", DashboardSummary);
Vue.component("kst-confirm", DialogConfirm);
Vue.component("kst-divider", Divider);
Vue.component("kst-dropdown", Dropdown);
Vue.component("kst-error", Error);
Vue.component("kst-errors", Errors);
Vue.component("kst-errors-helper", ErrorsHelper);
Vue.component("kst-failure", Failure);
Vue.component("kst-field", Field);
Vue.component("kst-form", Form);
Vue.component("kst-header-blank", HeaderBlank);
Vue.component("kst-icon", Icon);
Vue.component("kst-image", Image);
Vue.component("kst-input", Input);
Vue.component("kst-item-active", ItemActive);
Vue.component("kst-item-status", ItemStatus);
Vue.component("kst-last-updated", LastUpdated);
Vue.component("kst-list", List);
Vue.component("kst-load-data", LoadData);
Vue.component("kst-loading", Loading);
Vue.component("kst-menu", Menu);
Vue.component("kst-modal", Modal);
Vue.component("kst-modal-confirm", ModalConfirm);
Vue.component("kst-modal-input", ModalInput);
Vue.component("kst-modal-search", ModalSearch);
Vue.component("kst-modal-select", ModalSelect);
Vue.component("kst-modal-view", ModalView);
Vue.component("kst-number-with-date", NumberWithDate);
Vue.component("kst-output", Output);
Vue.component("kst-page", Page);
Vue.component("kst-page-blank", PageBlank);
Vue.component("kst-page-delete", PageDelete);
Vue.component("kst-page-details", PageDetails);
Vue.component("kst-page-edit", PageEdit);
Vue.component("kst-page-export", PageExport);
Vue.component("kst-page-export-details", PageExportDetails);
Vue.component("kst-page-list", PageList);
Vue.component("kst-page-new", PageNew);
Vue.component("kst-page-print", PagePrint);
Vue.component("kst-page-print-details", PagePrintDetails);
Vue.component("kst-page-print-list", PagePrintList);
Vue.component("kst-page-report", PageReport);
Vue.component("kst-page-search", PageSearch);
Vue.component("kst-print", Print);
Vue.component("kst-print-sign", PrintSign);
Vue.component("kst-print-table", PrintTable);
Vue.component("kst-router-back", RouterBack);
Vue.component("kst-router-forward", RouterForward);
Vue.component("kst-section", Section);
Vue.component("kst-section-summary", SectionSummary);
Vue.component("kst-skeleton", Skeleton);
Vue.component("kst-status", Status);
Vue.component("kst-subtitle", SubTitle);
Vue.component("kst-table", Table);
Vue.component("kst-table-empty", TableEmpty);
Vue.component("kst-title", Title);
Vue.component("kst-tooltip", Tooltip);
Vue.component("kst-upload", Upload);
Vue.component("kst-value", Value);
Vue.component("kst-wait", Wait);
Vue.component("kst-warning", Warning);
Vue.component("kst-recaptcha", VueRecaptcha);

/** formatter **/

// boolean
Vue.filter("activeInactiveFormat", KstApp.Format.getActiveInactive);
Vue.filter("booleanFormat", KstApp.Format.getBoolean);
Vue.filter("openClosedFormat", KstApp.Format.getOpenClosed);
Vue.filter("yesNoFormat", KstApp.Format.getYesNo);

// date
Vue.filter("shortDateFormat", KstApp.Format.getShortDate);
Vue.filter("rangeShortDate", KstApp.Format.getRangeShortDate);
Vue.filter("mediumDateFormat", KstApp.Format.getMediumDate);
Vue.filter("longDateFormat", KstApp.Format.getLongDate);

// number
Vue.filter("accountingFormat", KstApp.Format.getAccounting);
Vue.filter("accounting2DFormat", KstApp.Format.getAccounting2D);
Vue.filter("currencyFormat", KstApp.Format.getCurrency);
Vue.filter("decimalFormat", KstApp.Format.getDecimal);
Vue.filter("decimal2DFormat", KstApp.Format.getDecimal2D);
Vue.filter("integerFormat", KstApp.Format.getInteger);
Vue.filter("percentFormat", KstApp.Format.getPercent);

// converter
Vue.filter("arrayToString", KstApp.Convert.arrayToString);

/** router **/

import RouterModel from "./router/RouterModel.js";
import routes from "./router/index.js";
import AppModule from "./services/App/AppModule.js";

const data = {
  isAuthenticated: false,
  user: ""
};

const router = new VueRouter({
  mode: "history",
  routes: routes.getRoutes(true),
  scrollBehavior (to, from, savedPosition) {
    if (to.path === from.path) {
      if (savedPosition) {
        return savedPosition;
      }
      else {
        return { top: 0 };
      }
    }
    else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach(async (to, from, next) => {
  let moduleDetails, rootPath = "/";

  if (Object.prototype.hasOwnProperty.call(to, "meta")) {
    if (Object.keys(to.meta).length !== 0) {
      moduleDetails = Object.prototype.hasOwnProperty.call(to.meta, "module")
        ? to.meta.module : null;
    }
  }

  const hasSession = KstApp.Session.getName() !== null;
  // if route has module info
  if (moduleDetails) {
    // if module need Authentication
    if (moduleDetails.IsAuthenticated === true) {
      if (hasSession) {
        next();
      }
      else {
        router.push({ path: AppModule.SignIn.Path });
      }
    }
    else {
      // if route is going to sign in / sign up
      if (moduleDetails.Path === AppModule.SignIn.Path
          || moduleDetails.Path === AppModule.SignUp.Path
        ) {
        if (hasSession) {
          // go to default menu
          const defaultMenu = KstApp.Session.getDefaultMenu();
          let modulePath = null;

          for (let module of Object.values(AppModule)) {
            if (module.ID === defaultMenu) {
              modulePath = module.Path;
              break;
            }
          }

          let defaultPath = !defaultMenu ? rootPath : modulePath;
          router.push({ path: defaultPath });
        }
        else {
          next();
        }
      }
      else {
        next();
      }
    }
  }
  else {
    // if route is going to root
    if (to.path === rootPath) {
      if (hasSession) {
        const defaultMenu = KstApp.Session.getDefaultMenu();
        let modulePath = null;
  
        for (let module of Object.values(AppModule)) {
          // go to default menu
          if (module.ID === defaultMenu) {
            modulePath = module.Path;
            break;
          }
        }
  
        let defaultPath = !defaultMenu ? rootPath : modulePath;
        router.push({ path: defaultPath });
      }
      else {
        window.location.href = RouterModel.getPath(AppModule.SignIn);
        return;
      }
    }
    else {
      next();
    }
  }
});

router.afterEach((to) => {
  // update browser/history title
  Vue.nextTick(() => {
    document.title = RouterModel.getDocumentTitle(to);
  });
});

/** Google analytics website **/
import VueGtag from "vue-gtag";

Vue.use(VueGtag,
  {
    config: { id: "UA-208092480-1" }, onReady () {}
  },
  router
);

new Vue({
  render: h => h(App),
  data,
  router
}).$mount("#app");