import App from "../../services/App/App.js";
import AppCatalog from "../../services/App/AppCatalog.js";
import AppModule from "../../services/App/AppModule.js";
import Info from "./StopInfo.js";
import ModelBody from "./StopModel_Body.js";
import StopItemImportCatalog from "./StopItemImportCatalog.js";

export default {
  validateFileTemplate(errors, sheetRowList, file, fileText) {
    // verify file
    const fileExt = "." + file.name.split('.').pop();
    const allowedExt = AppCatalog.Xlsx.Default.Extension;
    const maxSize = AppCatalog.Xlsx.Default.MaxSize;

    if (!file) {
      errors.push(AppCatalog.Message.FileRequired);
    }
    
    if (fileExt != allowedExt) {
      errors.push(Info.Input.ItemImport.Error.SupportedFile);
    }

    if(file.size > maxSize) {
      errors.push(Info.Input.ItemImport.Error.MaxFileSize);
    }

    if (errors.length > 0) { return false; }

    // verify template
    const sheetObj = App.Xlsx.getSheet(
      fileText, Info.Export.New.Excel.SheetName.TemplateListItem
    );
    
    const headerList = App.Data.normalizeArray(sheetObj.Header);
    const rowList = App.Data.normalizeArray(sheetObj.RowList);
    const contentList = Info.Export.New.Excel.Template.ColumnInfo.Content;
    let isDifferentTemplate = false, allowedColumnList = [];
    
    for (const [index, content] of contentList.entries()) {
      if (index > 0) {
        allowedColumnList.push(content.Column);
      }
    }

    for (const [index, header] of headerList.entries()) {
      if (allowedColumnList[index] !== header) {
        isDifferentTemplate = true;
        break;
      }
    }

    const validationList = [
      headerList.length > 0,
      rowList.length > 0,
      headerList.length !== contentList.length,
      isDifferentTemplate === false
    ];

    if (validationList.includes(false)) {
      errors.push(Info.Input.ItemImport.Error.UnsupportedTemplate);
    }

    if (errors.length > 0) { return false; }

    sheetRowList.push(...rowList);
    return true;
  },

  validateRowByItem(itemValidList, itemInvalidList, sheetRowList, itemList) {
    let item = {}, itemObj = {}, itemDetails = {}, packagingDetails = {};
    let itemSheetObj = {}, packagingIndex = 0;

    for (const item of itemList) {
      if (!App.Data.hasProp(itemObj, item.ID)) {
        itemObj[item.ID] = item;
      }
    }

    for(const data of sheetRowList) {
      itemSheetObj = this.validateRowByItem_getItemImportData(data);

      // validate duplicate item disabled: handle by API

      // validate item
      if (!App.Data.hasProp(itemObj, itemSheetObj.ItemID)) {
        itemSheetObj.Message = StopItemImportCatalog.ItemID.Label + " " + 
          AppCatalog.Message.NotExist;
        itemInvalidList.push(itemSheetObj);
      }
      else {
        itemDetails = itemObj[itemSheetObj.ItemID];

        // validate name
        if (itemSheetObj.ItemName !== itemDetails.Name) {
          itemSheetObj.Message = StopItemImportCatalog.ItemName.Label + " " + 
            " pada " + StopItemImportCatalog.ItemID.Label + 
            " " +  AppCatalog.Message.InValid;
          itemInvalidList.push(itemSheetObj);
          continue;
        }

        // validate packaging name
        packagingIndex = itemDetails.PackagingList
          .findIndex((packaging) => packaging.Name === itemSheetObj.PackagingName);

        if (packagingIndex === -1) {
          itemSheetObj.Message = StopItemImportCatalog.PackagingName.Label + " " + 
            AppCatalog.Message.NotExist + 
            " atau " + AppCatalog.Message.InActive;
          itemInvalidList.push(itemSheetObj);
          continue;
        }

        packagingDetails = itemDetails.PackagingList[packagingIndex];

        if (packagingDetails.IsActive !== 1) {
          itemSheetObj.Message = AppModule.ItemPackaging.FullName + " " + 
            AppCatalog.Message.InActive;
          itemInvalidList.push(itemSheetObj);
          continue;
        }

        // validate packaging quantity
        if (packagingDetails.Qty !== itemSheetObj.PackagingQty) {
          itemSheetObj.Message = StopItemImportCatalog.PackagingQty.Label + " " + 
            AppCatalog.Message.InValid;
          itemInvalidList.push(itemSheetObj);
          continue;
        }

        // validate current qty
        if (itemSheetObj.Qty < 1 || 
          itemSheetObj.Qty === null || 
          itemSheetObj.Qty === undefined || 
          !App.Type.isInteger(itemSheetObj.Qty)
        ) {
          itemSheetObj.Message = StopItemImportCatalog.Qty.Label + " " + 
            AppCatalog.Message.MustNumeric + ", nilai minimum 0.";
          itemInvalidList.push(itemSheetObj);
          continue;
        }

        item = ModelBody.createItem();
        this.validateRowByItem_setItemsByImport(item, packagingDetails, 
          itemSheetObj, itemDetails);
        itemValidList.push(item);
      }
    }

    if (itemInvalidList.length > 0) { return false; }

    return true;
  },
  validateRowByItem_getItemImportData(rowData) {
    return {
      ItemID: rowData[0],
      ItemName: rowData[1],
      PackagingName: rowData[2],
      PackagingQty: rowData[3],
      Qty: rowData[4],
      Message: null
    };
  },
  validateRowByItem_setItemsByImport(item, packagingDetails, 
    itemSheetObj, itemDetails
  ) {
    item.DispatchID = itemDetails.ID;
    item.PackagingName = packagingDetails.Name;
    item.Qty = App.Out.getInteger(itemSheetObj.Qty);
    item.DispatchName = itemDetails.Name;
    item.SKU = itemDetails.SKU;
    item.DispatchSellPrice = packagingDetails.SellPrice;
    item.StockQty = itemDetails.Quantity;
    item.PackagingValue = packagingDetails.Qty;
    item.IsImport = true;
    ModelBody.updateItem(item);
  }
}