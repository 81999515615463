<template>
  <kst-page-print-details page-footer
    class="has-text-weight-bold ks-font-monospace"
    @init="handleInit"
  >
    <template #page-footer="{ label, timestamp }">
      {{ label }} {{ timestamp }}
    </template>

    <Title :companyData="companyData" :name="Model.Module.FullName"/>

    <Company class="block" :data="companyData" :customData="details"/>
    <Details :details="details"/>

    <kst-divider class="has-background-black"/>
    <Items :items="details.Items"/>

    <kst-divider class="has-background-black"/>
    <Total class="mb-5" :details="details"/>
  </kst-page-print-details>
</template>

<script>
import Model from "../RinvModel.js";
import Services from "../../../services/Api/ReturnInvoiceServices.js";

import Details from "./RinvPT_Details.vue";
import Title from "./RinvPT_Title.vue";
import Total from "./RinvPT_Total.vue";
import Items from "./RinvPT_Items.vue";
import Company from "../../Company/RelatedPrintThermal/CpPT.vue";

export default {
  components: {
    Details,
    Items,
    Title,
    Total,
    Company
  },
  data: () => ({
    Model,
    companyData: {},
    details: {}
  }),
  methods: {
    /*** by page ***/

    handleInit(id, loadPrint) {
      this.resetData();

      const config = Services.getPrintThermalData(id);
      loadPrint(config, this.handleInit_Success);
    },
    handleInit_Success(data) {
      this.companyData = data.CompanyDetails;
      this.details = data.Details;

      Model.PrintThermal.updateDetails(this.details);

      for (const item of this.details.Items) {
        Model.updateItem(item);
      }
    },

    /*** custom ***/

    resetData() {
      this.companyData = {};
      this.details = {};
    }
  }
}
</script>

<style scoped lang="css" src="./_layout.css"/>